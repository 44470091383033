<template>
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.00094 0C5.38094 0 3.25094 2.13 3.25094 4.75C3.25094 7.32 5.26094 9.4 7.88094 9.49C7.96094 9.48 8.04094 9.48 8.10094 9.49C8.12094 9.49 8.13094 9.49 8.15094 9.49C8.16094 9.49 8.16094 9.49 8.17094 9.49C10.7309 9.4 12.7409 7.32 12.7509 4.75C12.7509 2.13 10.6209 0 8.00094 0Z"
            fill="white" />
        <path
            d="M13.0809 12.15C10.2909 10.29 5.74094 10.29 2.93094 12.15C1.66094 13 0.960938 14.15 0.960938 15.38C0.960938 16.61 1.66094 17.75 2.92094 18.59C4.32094 19.53 6.16094 20 8.00094 20C9.84094 20 11.6809 19.53 13.0809 18.59C14.3409 17.74 15.0409 16.6 15.0409 15.36C15.0309 14.13 14.3409 12.99 13.0809 12.15Z"
            fill="white" />
        <path
            d="M18.9909 5.34C19.1509 7.28 17.7709 8.98 15.8609 9.21C15.8509 9.21 15.8509 9.21 15.8409 9.21H15.8109C15.7509 9.21 15.6909 9.21 15.6409 9.23C14.6709 9.28 13.7809 8.97 13.1109 8.4C14.1409 7.48 14.7309 6.1 14.6109 4.6C14.5409 3.79 14.2609 3.05 13.8409 2.42C14.2209 2.23 14.6609 2.11 15.1109 2.07C17.0709 1.9 18.8209 3.36 18.9909 5.34Z"
            fill="white" />
        <path
            d="M20.9909 14.59C20.9109 15.56 20.2909 16.4 19.2509 16.97C18.2509 17.52 16.9909 17.78 15.7409 17.75C16.4609 17.1 16.8809 16.29 16.9609 15.43C17.0609 14.19 16.4709 13 15.2909 12.05C14.6209 11.52 13.8409 11.1 12.9909 10.79C15.2009 10.15 17.9809 10.58 19.6909 11.96C20.6109 12.7 21.0809 13.63 20.9909 14.59Z"
            fill="white" />
    </svg>
</template>