<template>
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.8901 1.45H6.11008C5.71008 1.45 5.39008 1.13 5.39008 0.73C5.39008 0.33 5.71008 0 6.11008 0H11.8901C12.2901 0 12.6101 0.32 12.6101 0.72C12.6101 1.12 12.2901 1.45 11.8901 1.45Z"
            fill="white" />
        <path
            d="M16.9701 13H14.0301C12.7601 13 12.0001 13.76 12.0001 15.03V17.97C12.0001 19.24 12.7601 20 14.0301 20H16.9701C18.2401 20 19.0001 19.24 19.0001 17.97V15.03C19.0001 13.76 18.2401 13 16.9701 13ZM16.6901 17.46L15.5101 18.14C15.2701 18.28 15.0301 18.35 14.8101 18.35C14.6401 18.35 14.4901 18.31 14.3501 18.23C14.0301 18.04 13.8501 17.67 13.8501 17.18V15.82C13.8501 15.33 14.0301 14.96 14.3501 14.77C14.6701 14.58 15.0801 14.62 15.5101 14.86L16.6901 15.54C17.1101 15.79 17.3501 16.13 17.3501 16.5C17.3501 16.87 17.1201 17.21 16.6901 17.46Z"
            fill="white" />
        <path
            d="M11.0001 17.97V15.03C11.0001 13.22 12.2201 12 14.0301 12H16.9701C17.2001 12 17.4201 12.02 17.6301 12.06C17.6501 11.82 17.6701 11.58 17.6701 11.33C17.6701 6.54 13.7801 2.65 9.00008 2.65C4.22008 2.65 0.330078 6.54 0.330078 11.33C0.330078 16.11 4.22008 20 9.00008 20C9.85008 20 10.6601 19.86 11.4401 19.64C11.1601 19.17 11.0001 18.61 11.0001 17.97ZM9.75008 11C9.75008 11.41 9.41008 11.75 9.00008 11.75C8.59008 11.75 8.25008 11.41 8.25008 11V6C8.25008 5.59 8.59008 5.25 9.00008 5.25C9.41008 5.25 9.75008 5.59 9.75008 6V11Z"
            fill="white" />
    </svg>
</template>