import { http } from '../api';
import { Filters, PaginateRequest, PaginateResponse } from '../model';
import { CreateUserRequest, User } from '../model/User';
import { StatusUser } from '@/utils/StatusUser';

const controller = 'user';

const mountParams = (search: PaginateRequest & Filters) => {
  const queryParams = Object.entries(search)
    .filter(([_, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
  return `?${queryParams}`;
};

export async function getPaginate(
  search: PaginateRequest & Filters = { page: 1, limit: 10 }
): Promise<PaginateResponse<User>> {
  const { data } = await http.get<PaginateResponse<User>>(`${controller}/${mountParams(search)}`);

  return data;
}

export async function getPaginateAgents(
  paginate?: PaginateRequest,
  filters?: FiltersAgent
): Promise<PaginateResponse<Agent>> {
  const params: Record<string, string> = {};

  if (filters?.type && filters.items) {
    params[filters.type] = filters.items.join(',');
  }

  if (filters?.status) {
    params['status'] = filters.status;
  }

  const limit = paginate?.limit || 99999;
  const page = paginate?.page || 1;

  const queryString = new URLSearchParams(params).toString();

  const { data } = await http.get<PaginateResponse<Agent>>(
    `/${controller}/agent?limit=${limit}&page=${page}&${queryString}`
  );

  return { page: data.page, total: data.total, data: data.data, limit: data.limit, totalPages: data.totalPages };
}

export type FiltersAgent = {
  type?: string;
  items?: string[];
  status?: string;
};

export type Agent = {
  id: string;
  name: string;
  status: StatusUser;
  photo?: string;
  department?: string;
  allocatedService?: AllocatedService;
  lastSeen: string;
};

export type AllocatedService = {
  id: string;
  activity: {
    id: string;
    name: string;
  };
  location: string;
  acceptTimestamp: Date;
  timeExecution?: string;
};

export async function getBy(id: string): Promise<User> {
  const { data } = await http.get<User>(`${controller}/${id}`);
  return data;
}

export async function create(payload: CreateUserRequest): Promise<User> {
  const formData = new FormData();
  validateAndAppendAll(formData, payload);

  const { data } = await http.post<User>(`${controller}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
  return data;
}

export async function update(id: string, payload: CreateUserRequest): Promise<User> {
  const formData = new FormData();
  validateAndAppendAll(formData, payload);

  const { data } = await http.patch<User>(`${controller}/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
  return data;
}

export async function deleted(id: string): Promise<boolean> {
  const { status } = await http.delete(`${controller}/${id}`);
  return status === 204;
}

export async function forceLogout(id: string): Promise<void> {
  await http.post(`${controller}/agent/${id}/logout`);
}

function validateAndAppendAll(formData: FormData, payload: CreateUserRequest) {
  for (const [key, value] of Object.entries(payload)) {
    if (Object.prototype.hasOwnProperty.call(payload, key)) {
      if (value !== null && value !== undefined) {
        formData.append(key, value);
      }
    }
  }
}

export async function getCountAgents(filters: FiltersAgent): Promise<{
  total: number;
  waiting: number;
  running: number;
  offline: number;
}> {

  const params: Record<string, string> = {};

  if (filters?.type && filters.items) {
    params[filters.type] = filters.items.join(',');
  }

  const queryString = new URLSearchParams(params).toString();

  const { data } = await http.get(`/${controller}/agent/summary?${queryString}`);

  return data;
}
