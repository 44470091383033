import { http } from '../api';
import { FiltersDepartment, PaginateResponse } from '../model';
import { CreateDepartmentRequest, Department } from '../model/Department';

const controller = 'department';

export type DepartmentSearch = {
  page: number,
  limit: number,
  showDisabled?: boolean,
}

const mountParams = (search: DepartmentSearch & FiltersDepartment) => {
  const queryParams = Object.entries(search)
    .filter(([_, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
  return `?${queryParams}`;
};

export async function getPaginate(
  search: DepartmentSearch & FiltersDepartment = { page: 1, limit: 10000 }
): Promise<PaginateResponse<Department>> {
  const { data } = await http.get<PaginateResponse<Department>>(`${controller}/${mountParams(search)}`);
  return data;
}


export async function getBy(id: string): Promise<Department> {
  const { data } = await http.get<Department>(`${controller}/${id}`);
  return data;
}

export async function create(payload: CreateDepartmentRequest): Promise<Department> {
  const { data } = await http.post<Department>(`${controller}/`, payload);
  return data;
}

export async function update(id: string, payload: CreateDepartmentRequest): Promise<Department> {
  const { data } = await http.patch<Department>(`${controller}/${id}`, payload);
  return data;
}

export async function deleted(id: string, force?: boolean): Promise<void> {
  await http.delete(`${controller}/${id}`, {
    data: {
      forceDelete: force,
    },
    headers: { 'X-Custom-Error-Handled': 'true' },
  });
}

export async function enable(id: string): Promise<Department> {
  const { data } = await http.post(`${controller}/enable/${id}`);
  return data;
}

export async function disable(id: string): Promise<void> {
  await http.post(`${controller}/disable/${id}`, {}, {
    headers: { 'X-Custom-Error-Handled': 'true' },
  });
}
