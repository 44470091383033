import { FiltersDepartment, PaginateRequest, PaginateResponse } from '@/core/model';
import { Department } from '@/core/model/Department';
import { getPaginate } from '@/core/service/DepartmentService';
import { reactive } from 'vue';

const state = reactive<{ departments?: Department[] }>({
  departments: undefined,
});

const actions = {
  async GetPaginate(
    paginate: PaginateRequest,
    filters?: FiltersDepartment,
    refresh = false
  ): Promise<PaginateResponse<Department>> {
    if (refresh || !state.departments) {
      const response = await getPaginate({ ...paginate, ...filters });
      state.departments = response.data;
      return response;
    }
    return { data: state.departments || [], page: paginate.page, total: state.departments?.length || 0 };
  },
};

export { state, actions };
