import { FiltersLocation, PaginateRequest, PaginateResponse } from '@/core/model';
import { Location } from '@/core/model/Location';
import { getPaginate } from '@/core/service/LocationService';
import { reactive } from 'vue';

const state = reactive<{ locations?: Location[] }>({
  locations: undefined,
});

const actions = {
  async GetPaginate(
    paginate: PaginateRequest,
    filters?: FiltersLocation,
    refresh = false
  ): Promise<PaginateResponse<Location>> {
    if (refresh || !state.locations) {
      const response = await getPaginate({ ...paginate, ...filters });
      state.locations = response.data;
      return response;
    }
    return {
      data: state.locations || [],
      page: paginate.page,
      total: state.locations?.length || 0,
      limit: paginate.limit,
      totalPages: Math.ceil((state.locations?.length || 0) / (paginate.limit || 1)),
    };
  },
};

export { state, actions };
