import { reactive } from "vue";

const modalState = reactive({
    showConfirm: false,
    showSuccess: false,
    showError: false,
    isLoading: false,
    modalType: "",
    modalData: {
        title: "",
        description: "",
        confirmText: "Confirmar",
        cancelText: "Cancelar",
        badgeText: "Entidade:",
        currentEntity: "",
        successTitle: "Operação realizada com sucesso!",
        successDescription: "A operação foi concluída sem problemas.",
        errorTitle: "Erro ao realizar operação",
        errorDescription: "Houve um problema ao tentar realizar esta operação. Tente novamente.",
        callback: null as (() => Promise<void>) | null,
    },
});

const modalActions = {
    openConfirmModal({
        type,
        title,
        description,
        confirmText = "Confirmar",
        cancelText = "Cancelar",
        badgeText = "Entidade:",
        currentEntity,
        callback,
        successTitle = "Operação realizada com sucesso!",
        successDescription = "A operação foi concluída sem problemas.",
        errorTitle = "Erro ao realizar operação",
        errorDescription = "Houve um problema ao tentar realizar esta operação. Tente novamente.",
    }: {
        type: "delete" | "deactivate";
        title: string;
        description: string;
        confirmText?: string;
        cancelText?: string;
        badgeText?: string;
        currentEntity: string;
        callback: () => Promise<void>;
        successTitle?: string;
        successDescription?: string;
        errorTitle?: string;
        errorDescription?: string;
    }) {
        modalState.modalType = type;
        modalState.modalData = {
            title,
            description,
            confirmText,
            cancelText,
            badgeText,
            currentEntity,
            successTitle,
            successDescription,
            errorTitle,
            errorDescription,
            callback,
        };

        modalState.showConfirm = true;
    },

    async confirmAction(inputValue: string) {
        if (!modalState.modalData.callback) return;

        if (inputValue.trim().toUpperCase() !== "SIM") {
            console.error("Confirmação inválida! O usuário deve digitar 'SIM' para confirmar.");
            return;
        }

        try {
            modalState.isLoading = true;
            await modalState.modalData.callback();
            modalState.showConfirm = false;
            modalState.showSuccess = true;
        } catch (error: any) {
            modalState.showConfirm = false;
            modalState.modalData.errorDescription = error.data;
            modalState.showError = true;
        } finally {
            modalState.isLoading = false;
        }
    },

    closeConfirmModal() {
        modalState.showConfirm = false;
    },

    closeSuccessModal() {
        modalState.showSuccess = false;
    },

    closeErrorModal() {
        modalState.showError = false;
    },
};

export { modalState, modalActions };
