import { FiltersUser, PaginateRequest, PaginateResponse } from '@/core/model';
import { User } from '@/core/model/User';
import { getPaginate } from '@/core/service/UserService';
import { reactive } from 'vue';

const state = reactive<{ users?: User[] }>({
  users: undefined,
});

const actions = {
  async GetPaginate(
    paginate: PaginateRequest,
    filters?: FiltersUser,
    refresh = false
  ): Promise<PaginateResponse<User>> {
    if (refresh || !state.users) {
      const response = await getPaginate({ ...paginate, ...filters });
      state.users = response.data;
      return response;
    }
    return {
      data: state.users || [],
      page: paginate.page,
      total: state.users?.length || 0,
      limit: paginate.limit,
      totalPages: Math.ceil((state.users?.length || 0) / (paginate.limit || 1)),
    };
  },
};

export { state, actions };