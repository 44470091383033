import { http } from '../api';
import { Filters, FiltersCategory, PaginateRequest, PaginateResponse } from '../model';
import { CreateCategoryRequest, Category } from '../model/Category';

const controller = 'category';

const mountParams = (search: PaginateRequest & FiltersCategory) => {
  const queryParams = Object.entries(search)
    .filter(([_, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
  return `?${queryParams}`;
};

export async function getPaginate(
  search: PaginateRequest & FiltersCategory = { page: 1, limit: 10 }
): Promise<PaginateResponse<Category>> {
  const { data } = await http.get<PaginateResponse<Category>>(`${controller}/paginated${mountParams(search)}`);

  return data;
}


export async function getBy(id: string): Promise<Category> {
  const { data } = await http.get<Category>(`${controller}/${id}`);
  return data;
}

export async function getByDepartment(departmentId: string): Promise<Category[]> {
  const { data } = await http.get<Category[]>(`${controller}?department=${departmentId}`);
  return data;
}

export async function create(payload: CreateCategoryRequest): Promise<Category> {
  const { data } = await http.post<Category>(`${controller}/`, payload);
  return data;
}

export async function update(id: string, payload: CreateCategoryRequest): Promise<Category> {
  const { data } = await http.patch<Category>(`${controller}/${id}`, payload);
  return data;
}

export async function deleted(id: string, force: boolean): Promise<void> {
  await http.delete(`${controller}/${id}`, {
    data: {
      forceDelete: force,
    },
    headers: { 'X-Custom-Error-Handled': 'true' },
  });
}

export async function enable(id: string): Promise<Category> {
  const { data } = await http.post(`${controller}/enable/${id}`);
  return data;
}

export async function disable(id: string): Promise<void> {
  await http.post(`${controller}/disable/${id}`, {}, {
    headers: { 'X-Custom-Error-Handled': 'true' },
  });
}