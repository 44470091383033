import { http } from '../api';
import { Filters, FiltersActivity, PaginateRequest, PaginateResponse } from '../model';
import { CreateActivityRequest, Activity } from '../model/Activity';

const controller = 'activity';

export async function getByCategory(categoryId: string): Promise<Activity[]> {
  const { data } = await http.get<Activity[]>(`${controller}/category/${categoryId}`);
  return data;
}

const mountParams = (search: PaginateRequest & FiltersActivity) => {
  const queryParams = Object.entries(search)
    .filter(([_, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
  return `?${queryParams}`;
};

export async function getPaginate(
  search: PaginateRequest & FiltersActivity = { page: 1, limit: 10 }
): Promise<PaginateResponse<Activity>> {

  const { data } = await http.get<PaginateResponse<Activity>>(`${controller}${mountParams(search)}`);

  return data;
}

export async function getBy(id: string): Promise<Activity> {
  const { data } = await http.get<Activity>(`${controller}/${id}`);
  return data;
}

export async function create(payload: CreateActivityRequest): Promise<Activity> {
  const formData = new FormData();
  validateAndAppendAll(formData, payload);

  const { data } = await http.post<Activity>(`${controller}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
}

export async function update(id: string, payload: CreateActivityRequest): Promise<Activity> {
  const formData = new FormData();
  validateAndAppendAll(formData, payload);

  const { data } = await http.patch<Activity>(`${controller}/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
}

export async function deleted(id: string, force?: boolean): Promise<void> {
  await http.delete(`${controller}/${id}`, {
    data: {
      forceDelete: force,
    },
    headers: { 'X-Custom-Error-Handled': 'true' },
  });
}


export async function enable(id: string): Promise<Activity> {
  const { data } = await http.post(`${controller}/enable/${id}`);
  return data;
}

export async function disable(id: string): Promise<Activity> {
  const { data } = await http.post(`${controller}/disable/${id}`);
  return data;
}

function validateAndAppendAll(formData: FormData, payload: CreateActivityRequest) {
  for (const [key, value] of Object.entries(payload)) {
    if (Object.prototype.hasOwnProperty.call(payload, key)) {
      if (value !== null && value !== undefined) {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            if (item instanceof File) {
              formData.append(`${key}`, item);
            } else {
              formData.append(`${key}`, String(item));
            }
          });
        } else {
          formData.append(key, String(value));
        }
      }
    }
  }
}

