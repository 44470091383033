import { http } from '../api';
import qs from 'qs';
import { ServiceAgent, ServiceAgentResponse } from '../model/Services';
import { priorityMapping } from '../enum/priority';

const controller = 'scservice';

type ServiceCreate = {
  activityId: string,
  quantity: number,
  operationId: number,
  location: string,
  priority: number,
  client: any,
  description: string
  triggerActivities?: Array<string>
}

export type ServicePendingResponse = {
  id: string;
  name: string;
  services: ServicePendingTableResponse[];
};

export type ServicePendingTableResponse = {
  id: string;
  activity: {
    name: string;
  };
  location: string;
  priority: number;
  slaStatus: string;
}

export type ServicePending = {
  id: string;
  name: string;
  services: ServicePendingTable[];
};

export type ServicePendingTable = {
  id: string;
  activity: {
    name: string;
  };
  location: string;
  priority: string;
  slaStatus: string;
}


export type ServicePendingDetails = {
  id: string;
  activity: {
    id: string;
    name: string;
  };
  category: {
    id: string;
    name: string;
  };
  quantity: number;
  location: string;
  priority: number;
  client?: string;
  description: string;
  slaStatus: string;
  startTimestamp?: Date;
  endTimestamp?: Date;
  interruptTimestamp?: Date;
  cancelTimestamp?: Date;
  endEstimated?: Date;
  cancelReason?: string;
  interruptReason?: string;
  agent?: {
    name: string;
    job?: string;
    photo?: string;
  };
  agentFeedback?: string;
}

export interface IRelatoryFilters {
  limit?: number;
  page?: number;
  start_date?: string;
  end_date?: string;
  location?: string;
  slaStatusExceed?: string;
  categoryId?: string;
  activityId?: string;
  departmentId?: string;
  doc?: string;
  rated?: boolean;
  requestingAgent?: string;
  alocatedAgent?: string;
  badReviews?: boolean;
  rate?: number;
  slaStatus?: string;
  columns?: string;
}

export type ReportResponse = {
  total: number;
  page: number;
  limit: number;
  totalPages: number;
  data: Report[];
};

export type Report = {
  startDate: string;
  location?: string;
  slaExceeded?: string;
  department?: string;
  category?: string;
  activity?: string;
  status?: string;
  alocatedAgent?: string;
  client?: string;
  quantity?: number;
  totalDuration?: string;
  enabled: boolean;
  clientFeedback?: string;
  rating?: number;
};

export type Relatory = {
  id: string;
  withDeleted: boolean;
  requestingAgent: string;
  attendanceId: string;
  dt_nascimento: string;
  nm_paciente: string;
  ds_leito: string;
  cd_leito: string;
  unidade_internacao: string;
  bed: string;
  activityId: string;
  categoryId: string;
  requestTimestamp: string;
  startTimestamp: Date;
  approveTimestamp: Date;
  rejectTimestamp: Date;
  acceptTimestamp: Date;
  endTimestamp: string;
  archiveTimestamp: Date;
  cancelTimestamp: Date;
  slaAcceptTimeExceded: string;
  slaStartTimeExceded: string;
  slaEndTimeExceded: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  rating: number | number[];
  companyId: string;
  slaStatus: string;
  badReviews?: boolean;
};
export async function create(service: ServiceCreate): Promise<void> {
  await http.post(`${controller}`, service);
}

export async function getRelatory(payload: IRelatoryFilters): Promise<ReportResponse> {
  const params = qs.stringify(
    {
      ...payload,
      order: 'DESC',
      withDeleted: true,
    },
    { indices: false, skipNulls: true }
  );

  const { data } = await http.get<ReportResponse>(`${controller}/getReport?${params}`);

  const items = data.data.map((d) => {
    return {
      ...d,
      slaExceeded: getSlaText(d.slaExceeded ?? ''),
    };
  });

  return {
    ...data,
    data: items,
  };
}

export async function getReport(payload: IRelatoryFilters): Promise<string> {
  const params = qs.stringify(
    {
      ...payload,
      order: 'DESC',
      withDeleted: true,
    },
    { indices: false, skipNulls: true }
  );

  const { data } = await http.get(`${controller}/report/download?${params}`);

  return data.link;
}

function getSlaText(input: string) {
  switch (input) {
    case 'exceded':
      return 'Excedido';
    case 'normal':
      return 'Normal';
    case 'warning':
      return 'Quase Excedido';
    default:
      return '';
  }
}

export async function getServicesPending(
  departmentIds?: string[],
  hoursAgo?: number,
  totalItens?: number
): Promise<ServicePending[]> {
  const params = new URLSearchParams();

  if (departmentIds) params.append("departments", departmentIds.join(","));
  if (hoursAgo) params.append("hoursAgo", hoursAgo.toString());
  if (totalItens) params.append("totalItens", totalItens.toString());

  const { data } = await http.get<ServicePendingResponse[]>(
    `${controller}/recent?${params.toString()}`
  );

  const mappedData: ServicePending[] = data.map((department) => ({
    id: department.id,
    name: department.name,
    services: department.services.map((service) => ({
      id: service.id,
      activity: service.activity,
      location: service.location,
      priority: priorityMapping[service.priority] || "Desconhecida",
      slaStatus: service.slaStatus,
    })),
  }));

  return mappedData;
}



export async function getServicePending(serviceId?: string): Promise<ServicePendingDetails> {
  const { data } = await http.get<ServicePendingDetails>(`${controller}/recent/${serviceId}`);
  return data;
}

export async function getServicesAgent(
  agentId: string,
  order: 'ASC' | 'DESC' = "DESC",
  statusOrder: 'ASC' | 'DESC' = "ASC",
  slaStatus?: string,
): Promise<ServiceAgent[]> {
  const queryParams = [
    `page=1`,
    `limit=20`,
    slaStatus ? `slaStatus=${slaStatus}` : '',
    `order=${order}`,
    `statusOrder=${statusOrder}`,
  ].filter(Boolean).join('&');

  const { data } = await http.get<ServiceAgentResponse>(`${controller}/agent/${agentId}?${queryParams}`);

  return data.data;
}


export async function interruptService(id: string, reason: string): Promise<void> {
  try {
    await http.post(`${controller}/interrupt/${id}`, { reason });
  } catch (error) {
    throw new Error('Erro ao interromper o serviço: ' + error);
  }
}

export async function cancelService(id: string, reason: string): Promise<void> {
  try {
    await http.post(`${controller}/cancel/${id}`, { cancelReason: reason });
  } catch (error) {
    throw new Error('Erro ao cancelar o serviço: ' + error);
  }
}

export async function retakeService(id: string, agentId?: string): Promise<void> {
  try {
    const data: { agentId?: string } = {};

    if (agentId) {
      data.agentId = agentId;
    }

    await http.post(`${controller}/retake/${id}`, data);
  } catch (error) {
    throw new Error('Erro ao retomar o serviço: ' + error);
  }
}

