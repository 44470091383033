<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.22222 5C1.54721 5 1 5.5777 1 6.29032C1 7.00295 1.54721 7.58065 2.22222 7.58065H21.7778C22.4528 7.58065 23 7.00295 23 6.29032C23 5.5777 22.4528 5 21.7778 5H2.22222Z"
            fill="#162445" />
        <path
            d="M4.97222 10.8065C4.29721 10.8065 3.75 11.3841 3.75 12.0968C3.75 12.8094 4.29721 13.3871 4.97222 13.3871H19.0278C19.7028 13.3871 20.25 12.8094 20.25 12.0968C20.25 11.3841 19.7028 10.8065 19.0278 10.8065H4.97222Z"
            fill="#162445" />
        <path
            d="M6.5 17.9032C6.5 17.1906 7.04721 16.6129 7.72222 16.6129H16.2778C16.9528 16.6129 17.5 17.1906 17.5 17.9032C17.5 18.6159 16.9528 19.1935 16.2778 19.1935H7.72222C7.04721 19.1935 6.5 18.6159 6.5 17.9032Z"
            fill="#162445" />
    </svg>
</template>