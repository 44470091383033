import { Filters, FiltersCategory, PaginateRequest, PaginateResponse } from '@/core/model';
import { Category } from '@/core/model/Category';
import { getPaginate } from '@/core/service/CategoryService';
import { reactive } from 'vue';

const state = reactive<{ categories?: Category[] }>({
  categories: undefined,
});

const actions = {
  async GetPaginate(
    paginate: PaginateRequest,
    filters?: FiltersCategory,
    refresh = false
  ): Promise<PaginateResponse<Category>> {
    if (refresh || !state.categories) {
      const response = await getPaginate({ ...paginate, ...filters });
      state.categories = response.data;
      return response;
    }
    return { data: state.categories || [], page: paginate.page, total: state.categories?.length || 0 };
  },
};

export { state, actions };
