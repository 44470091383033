import { Authentication } from '@/store';
import { http } from '../api';
import { Filters, FiltersLocation, PaginateRequest, PaginateResponse } from '../model';
import { CreateLocationRequest, Location } from '../model/Location';

const controller = 'location';

const mountParams = (search: PaginateRequest & FiltersLocation) => {
  const queryParams = Object.entries(search)
    .filter(([_, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
  return `?${queryParams}`;
};

export async function getPaginate(
  search: PaginateRequest & FiltersLocation = { page: 1, limit: 10 }
): Promise<PaginateResponse<Location>> {
  const { data } = await http.get<PaginateResponse<Location>>(`${controller}/${mountParams(search)}`);

  return data;
}


export async function getBy(id: string): Promise<Location> {
  const { data } = await http.get<Location>(`${controller}/${id}`);
  return data;
}

export async function create(payload: CreateLocationRequest): Promise<Location> {
  const companyId = Authentication.state.value?.company.id;

  const requestBody = {
    ...payload,
    companyId,
  };

  const { data } = await http.post<Location>(`${controller}`, requestBody);
  return data;
}


export async function update(id: string, payload: CreateLocationRequest): Promise<Location> {
  const { data } = await http.patch<Location>(`${controller}/${id}`, { ...payload, id });
  return data;
}

export async function deleted(id: string): Promise<boolean> {
  const { status } = await http.delete(`${controller}/${id}`);
  return status === 204;
}
