export const formatNumber = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });

export const formatLang = (value: number): string => {
    return new Intl.NumberFormat('pt-BR').format(value);
}

export const extrairNumerosMoedaBRL = (str: string) => {
    const numerosString = str.replace(/[^\d,]/g, '');

    const resultado = parseFloat(numerosString.replace(',', '.'));

    return isNaN(resultado) ? null : resultado;
}

export const formatPercentage = (value: number, fixed = 2) => {
    return Number(value).toFixed(fixed).replace('.', ',') + "%";
}

export const formatCurrency = (number: number): string => {
    if (isNaN(number)) return '0,00';

    const stringValue = (number / 100).toFixed(2);
    const [integers, decimals] = stringValue.split('.');

    const formattedIntegers = integers.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return `${formattedIntegers},${decimals}`;
};


export function formatTriggerItemPercentageLabel(start_value: number, end_value: number) {
    return `${Number(start_value).toFixed(0) == "0" ? (Number(start_value).toFixed(0) + ",00") : (Number(start_value).toFixed(0) + ",01") + "%"} - ${formatPercentage(Number(end_value))}`;
}
