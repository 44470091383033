import { Authentication } from '@/store';
import { getColors } from '@/styles/updateColors';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../pages/login/loginPage.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../pages/dashboard/DashboardPage.vue'),
    meta: {
      icon: 'HouseIcon',
      divider: true,
    },
  },
  {
    path: '/servicos',
    name: 'Serviços',
    component: () => import('../pages/service/ServicePage.vue'),
    meta: {
      icon: 'HouseIcon',
      divider: true,
    },
  },
  {
    path: '/agentes',
    name: 'Agentes',
    component: () => import('../pages/agents/AgentsPage.vue'),
    meta: {
      icon: 'HouseIcon',
      divider: true,
    },
  },
  {
    path: '/analysis/activities',
    name: 'Análise Serviços',
    component: () => import('../pages/analysis/pages/ActivitiesPage.vue'),
    meta: {
      icon: 'HouseIcon',
      divider: false,
    },
  },
  {
    path: '/analysis/agents',
    name: 'Análise Agentes',
    component: () => import('../pages/analysis/pages/AgentsPage.vue'),
    meta: {
      icon: 'HouseIcon',
      divider: false,
    },
  },
  {
    path: '/relatorios',
    name: 'Relatórios',
    component: () => import('../pages/relatory/RelatoryPage.vue'),
    meta: {
      icon: 'HouseIcon',
      divider: false,
    },
  },
  {
    path: '/settings',
    name: 'Configurações',
    component: () => import('../pages/settings/SettingsPage.vue'),
    meta: {
      icon: 'MonitorMobbileIcon',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../pages/common/NotFound.vue')
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  Authentication.actions.ActionCheckToken();
  getColors();
  const token = Authentication.getters.getUser.value?.token;

  if (!token && to.name !== 'Login') {
    next({ name: 'Login' });
  } else if (token && to.path == '/') {
    next('/servicos');
  } else {
    next(true);
  }
});
export { routes };
export default router;
